import React, { useMemo } from "react";
import { Link } from "gatsby";
import { FaRegPlayCircle } from "react-icons/fa";
import { kebabCase, getGatsbyImage, getDate } from "helpers";
import { ImPause2, ImFileText2 } from "react-icons/im";
import { VscLoading } from "react-icons/vsc";
import usePlayer from "state/usePlayer";
import _ from "lodash";

/* Play Episode
============================================================================= */

const PlayEpisode = ({ episode, location, noTranscript = false }) => {
	// Config
	const {
		setEpisode,
		episodes,
		setPlaying,
		addEpisode,
		playing,
		setLoading,
		loading,
		episode: episodeIndex,
	} = usePlayer();

	// Render Playing
	const renderPlaying = () => {
		if (
			episodes[episodeIndex].episode === episode.episode_id &&
			episodes[episodeIndex].show === episode.podcast.podcast_id &&
			loading
		) {
			return (
				<>
					<VscLoading
						color="black"
						size={30}
						className="animate-spin"
					/>
					<span className="pl-2 font-semibold font-body">
						LISTEN NOW
					</span>
				</>
			);
		}
		if (
			episodes[episodeIndex].episode === episode.episode_id &&
			episodes[episodeIndex].show === episode.podcast.podcast_id &&
			playing
		) {
			return (
				<>
					<ImPause2 color="black" size={30} />
					<span className="pl-2 font-semibold font-body">
						LISTEN NOW
					</span>
				</>
			);
		}
		return (
			<>
				<FaRegPlayCircle color="black" size={30} />
				<span className="pl-2 font-semibold font-body">LISTEN NOW</span>
			</>
		);
	};

	// Render
	return (
		<div className="flex flex-row items-center justify-start w-full px-6 py-6 pr-6 mt-4 bg-gray-100 rounded-md outline-none group">
			<button
				aria-label="Listen Now"
				style={{ outline: "0px" }}
				className={`${
					loading ? "opacity-70 pointer-events-none" : ""
				} flex flex-row items-center justify-start focus outline-none`}
				onClick={() => {
					const player = document.getElementById("acast-player");
					if (
						episodes[episodeIndex].episode === episode.episode_id &&
						episodes[episodeIndex].show ===
							episode.podcast.podcast_id &&
						playing
					) {
						setPlaying(false);
						const message = {
							eventName: "postmessage:do:pause",
						};
						player.contentWindow.postMessage(
							JSON.stringify(message),
							"*"
						);
						return;
					}
					let message;
					const index = _.findIndex(episodes, (el) => {
						return (
							el.show === episode.podcast.podcast_id &&
							el.episode === episode.episode_id
						);
					});
					if (index === 0 || index !== -1) {
						setEpisode(index);
						setLoading(true);
						message = {
							eventName: "postmessage:do:play",
							data: {
								show: episodes[index].show,
								episode: episodes[index].episode,
							},
						};
						setPlaying(true);
					} else {
						addEpisode({
							show: episode.podcast.podcast_id,
							episode: episode.episode_id,
							showName: episode.podcast.name,
							episodeName: episode.name,
						});
						setEpisode(episodes.length);
						setLoading(true);
						message = {
							eventName: "postmessage:do:play",
							data: {
								show: episode.podcast.podcast_id,
								episode: episode.episode_id,
							},
						};
						setPlaying(true);
					}
					player.contentWindow.postMessage(
						JSON.stringify(message),
						"*"
					);
				}}
			>
				{renderPlaying()}
			</button>
			{!noTranscript ? (
				<Link
					to={`/podcasts/${kebabCase(
						episode.podcast.name
					)}/${kebabCase(episode.name)}/transcript`}
					className="flex flex-row items-center justify-start ml-4 font-semibold font-body"
				>
					<span className="mr-4 text-lg ">&bull;</span>
					<ImFileText2 color="black" size={26} />
					<span className="pl-2 font-body">TRANSCRIPT</span>
				</Link>
			) : null}
		</div>
	);
};

/* Export
============================================================================= */

export default PlayEpisode;
