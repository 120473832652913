import React from "react";
import { Section } from "components/layout";
import { EpisodeItem } from "components";

/* Next Episode
============================================================================= */

const NextEpisode = ({ episode, episodeNumber }) => {
	// Config

	// Render
	return (
		<Section title="Next Episode" ariaLabel="Next Episode">
			<EpisodeItem episode={episode} episodeNumber={episodeNumber} />
		</Section>
	);
};

/* Export
============================================================================= */

export default NextEpisode;
