import React, { useEffect } from "react";
import { Link } from "gatsby";
import { SEO, Listen } from "components";
import { PreviousEpisode, NextEpisode, PlayEpisode } from "components/episode";
import {
	LatestEpisodesList,
	HostsList,
	ExtendedDescription,
	Gallery,
	RelatedContent,
} from "components/podcast";
import { Banner } from "components/transcript";
import { Section } from "components/layout";
import { TrailerItem } from "components";
import Image from "gatsby-image";
import Helmet from "react-helmet";
import { getGatsbyImage } from "helpers";
import _ from "lodash";
import BlockContent from "@sanity/block-content-to-react";
import useMenu from "state/useMenu";
/* Podcast Transcript Template
============================================================================= */

const PodcastTranscriptTemplate = ({ pageContext, location }) => {
	// Config
	const { podcast } = pageContext;
	const { setAd } = useMenu();

	useEffect(() => {
		setAd();
	}, []);
	// Render
	return (
		<>
			<section
				role="region"
				aria-label="Play Trailer"
				className={`w-full horizontal-padding relative pt-2`}
			>
				<TrailerItem podcast={podcast} noTranscript={true} />
			</section>
			<Section
				role="region"
				title="Trailer Transcript"
				ariaLabel="Trailer Transcript"
				className="horizontal-padding padding-top padding-bottom"
			>
				<BlockContent
					blocks={podcast._rawTranscript}
					className="block-content prose-lg"
					renderContainerOnSingleChild
				/>
			</Section>
		</>
	);
};

/* Export
============================================================================= */

export default PodcastTranscriptTemplate;
