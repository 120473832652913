import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";
import { Listen } from "components";
import { getGatsbyImage, getDate } from "helpers";
import BlockContent from "@sanity/block-content-to-react";
import {
	FaSpotify,
	FaRssSquare,
	FaFacebookSquare,
	FaTwitterSquare,
	FaShareAlt,
} from "react-icons/fa";
import PlayEpisode from "./playEpisode";
import { Twitter, Facebook } from "react-social-sharing";

/* Banner
============================================================================= */

const Banner = ({ episode, podcast, location }) => {
	// Config

	// Render
	return (
		<section
			role="region"
			aria-label={episode.name}
			className="relative z-50 grid w-full grid-cols-1 horizontal-padding padding-top md:grid-cols-8 "
		>
			<div className="col-span-2 rounded-md">
				<Image
					fluid={getGatsbyImage(podcast.image)}
					alt={podcast.image.alt}
					className="w-full rounded-md shadow-xl square-image"
					placeholderStyle={{
						filter: "blur(50px)",
					}}
					imgStyle={{
						objectFit: "cover",
						objectPosition: "50% 50%",
					}}
				/>
			</div>
			<div className="flex flex-col items-start justify-start col-span-6 pt-4 overflow-hidden md:pl-10 md:pt-0">
				<h4 className="pb-1 text-sm tracking-wider text-black uppercase font-body">
					<span className="font-semibold text-gold">
						{episode.podcast.name}
					</span>{" "}
					&bull;{" "}
					<time>
						{episode.podcast.name === "RISING 2021 Podcast"
							? "5 MAY 2021"
							: getDate(episode.date)}
					</time>
				</h4>
				<h2
					role="heading"
					className="pb-2 text-4xl text-left text-black font-display"
				>
					{episode.name}
				</h2>
				<BlockContent
					blocks={episode._rawDescription}
					className="text-lg prose text-black block-content font-body"
					renderContainerOnSingleChild
				/>
				<Listen podcast={podcast} />
				<ul
					role="list"
					aria-label="Podcast Sharing Options"
					className="flex flex-row flex-wrap items-start justify-start hidden w-full col-span-5 pt-2 "
				>
					<p className="mr-6 text-xl font-bold tiresias">Share</p>
					<li className="social-sharing-twitter">
						<Twitter link={location.href} />
					</li>
					<li className="social-sharing-facebook">
						<Facebook link={location.href} />
					</li>
				</ul>
			</div>
		</section>
	);
};

/* Export
============================================================================= */

export default Banner;
