import React from "react";
import { Section } from "components/layout";
import { EpisodeItem } from "components";

/* Previous Episode
============================================================================= */

const PreviousEpisode = ({ episode, episodeNumber }) => {
	// Config

	// Render
	return (
		<Section title="Previous Episode" ariaLabel="Previous Episode">
			<EpisodeItem episode={episode} episodeNumber={episodeNumber} />
		</Section>
	);
};

/* Export
============================================================================= */

export default PreviousEpisode;
