import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";
import { Listen } from "components";
import { getGatsbyImage, getDate } from "helpers";
import {
	FaSpotify,
	FaRssSquare,
	FaFacebookSquare,
	FaTwitterSquare,
	FaShareAlt,
} from "react-icons/fa";
import { Twitter, Facebook } from "react-social-sharing";

/* Banner
============================================================================= */

const Banner = ({ episode, podcast }) => {
	// Config

	// Render
	return (
		<section
			role="region"
			aria-label={episode.name}
			className="w-full horizontal-padding padding-top grid md:grid-cols-8 grid-cols-1 relative z-50 "
		>
			<div className="col-span-2 rounded-md">
				<Image
					fluid={getGatsbyImage(podcast.image)}
					alt={podcast.image.alt}
					className="square-image w-full shadow-xl rounded-md"
					placeholderStyle={{
						filter: "blur(50px)",
					}}
					imgStyle={{
						objectFit: "cover",
						objectPosition: "50% 50%",
					}}
				/>
			</div>
			<div className="col-span-6 flex flex-col items-start justify-start overflow-hidden pb-4 md:pl-8">
				<h4 className="font-body text-sm text-black uppercase tracking-wider pb-1">
					<span className="font-semibold text-gold">
						{episode.podcast.name}
					</span>{" "}
					&bull; <time>{getDate(episode.date)}</time>
				</h4>
				<h2
					role="heading"
					className="font-display text-4xl text-left text-black pb-2"
				>
					{episode.name}
				</h2>
				<p className="prose text-black text-lg font-body">
					{episode.description}
				</p>
			</div>
		</section>
	);
};

/* Export
============================================================================= */

export default Banner;
